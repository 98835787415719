import { Injectable } from '@angular/core';
import { ChatbotConfig, ChatbotDataConfig } from '../../../_models/chatbot/chatbot-config.model';
import { LoadExternalFileService } from '../../_helper-services/load-external-file.service';

declare var ChatBot: any;

@Injectable()
export class ChatBotLibService{

  constructor(private loadExternalFileService: LoadExternalFileService) {

  }

  public async initializeChatBot(chatbotConfig: ChatbotConfig, chatbotDataConfig: ChatbotDataConfig): Promise<void> {
    if (chatbotConfig) {
      await this.loadExternalFileService.loadExternalScript(chatbotConfig.scriptUrl);
      ChatBot.init(
        {
          rootElementId: chatbotConfig.rootElementId,
          config: {
            apiKey: chatbotConfig.apiKey,
            apiUrl: chatbotConfig.apiUrl,
            title: chatbotConfig.title,
            logoUrl: chatbotConfig.logoUrl,
            logoAlt: chatbotConfig.logoAlt,
            logoBorder: chatbotConfig.logoBorder,
            bubbleUrl: chatbotConfig.bubbleUrl,
            bubleAlt: chatbotConfig.bubleAlt,
            brandColor: chatbotConfig.brandColor,
            dataPersistent: chatbotConfig.dataPersistent,
            crossTabSync: chatbotConfig.crossTabSync
          },
          data: chatbotDataConfig
        });
    }
  }

  public setChatbotData(chatbotDataConfig: ChatbotDataConfig)
  {
    if(this.isChatbotAvailable() && chatbotDataConfig)
    {
     
      ChatBot.setData(
        {    
            customerNumber: chatbotDataConfig.customerNumber,
            accountNumber: chatbotDataConfig.accountNumber
        });
    }
  }

  public isChatbotAvailable(): boolean {
    return typeof ChatBot !== "undefined";
  }

  public resetChatbot()
  {
    if(this.isChatbotAvailable())
    {
      ChatBot.restart();
    }
  }

  public closeChatbot()
  {
    if(this.isChatbotAvailable())
    {
      ChatBot.close();
    }
  }

}
