import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadExternalFileService {

  constructor() {

  }

  public loadExternalScript(scriptUrl: string) {
    var alreadyLoaded = false;

    for (var scriptIndex in document.scripts) {
      if (!alreadyLoaded && scriptUrl === document.scripts[scriptIndex].src) {
        alreadyLoaded = true;
      }
    }

    if (!alreadyLoaded) {
      return new Promise((resolve, reject) => {
        const body = <HTMLDivElement>document.body;
        const script = document.createElement('script');
        script.innerHTML = '';
        script.src = scriptUrl;
        script.onload = resolve;
        script.onerror = function(){
          reject();
        };
        body.appendChild(script);
      });
    } else {
      return new Promise(resolve => {

      });
    }
  }
}

